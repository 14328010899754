import React from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';

// Custom MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
        <Container maxWidth="md" sx={{ py: 5 }}>
          {/* Header */}
          <header>
            <Box sx={{ textAlign: 'center', py: 3 }}>
              <Typography variant="h2" color="primary" gutterBottom>
                Exla — LLM inference from the basic fundamentals
              </Typography>
              <Typography variant="h5" color="text.secondary">
                Cheapest inference, not the fastest yet.
              </Typography>
            </Box>
          </header>

          {/* About Section */}
          <section>
            <Box sx={{ my: 4, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#fff' }}>
              <Typography variant="h4" color="primary" gutterBottom>
                About Exla
              </Typography>
              <Typography variant="body1">
                Building LLMs from registers to application, optimized for constrained hardware.
              </Typography>
            </Box>
          </section>

          {/* Features Section */}
          <section>
            <Box sx={{ my: 4, p: 3, borderRadius: 2, boxShadow: 3, backgroundColor: '#fff' }}>
              <Typography variant="body1" color="text.primary">
                Building the world's most efficient chips for LLM inference.
              </Typography>
            </Box>
          </section>

          {/* Footer */}
          <footer>
            <Box sx={{ textAlign: 'center', mt: 5, py: 3, borderTop: 1, borderColor: 'grey.300' }}>
              <Typography variant="body2" color="text.secondary">
                &copy; {new Date().getFullYear()} Exla | Contact us on{' '}
                <Link href="https://twitter.com/therealviraat" color="primary">
                  Twitter
                </Link>
              </Typography>
            </Box>
          </footer>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default App;
